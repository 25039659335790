const preprocess_customer_data = (data, timeframe) => {
    const customer_count = new Array(data.length).fill(0);
    data.forEach((item, index) => { 
        customer_count[index] = item.customer_count;
    });

    let times = [];
    if (timeframe === "year")
        times = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    if (timeframe === "month")
        times = data.map(item => "Week " + item.week);
    if (timeframe === "week")
        times = data.map(item => item.date);

    return { times, customer_count };
}

export default preprocess_customer_data;