const preprocess_policy = (data, timeframe) => {
    // Extract unique category IDs
    const categoryIds = new Set();
    data.forEach(item => {
        item.categories.forEach(category => {
            categoryIds.add(category.category_id);
        });
    });

    // Create an object to store policy counts for each category
    const policyCountsByCategory = {};
    categoryIds.forEach(categoryId => {
        policyCountsByCategory[categoryId] = new Array(data.length).fill(0);
    });

    // Fill policy counts based on data
    data.forEach((item, monthIndex) => {
        item.categories.forEach(category => {
            policyCountsByCategory[category.category_id][monthIndex] = category.policy_count;
        });
    });


    let months = [];
    // Create an array of months
    if (timeframe === "year")
        months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    if (timeframe === "month")
        months = data.map(item => "Week " + item.week_number);
    if (timeframe === "week")
        months = data.map(item => item.date);

    // Convert policy counts to arrays, with missing values replaced by 0
    const policyCounts = Object.values(policyCountsByCategory).map(counts => {
        return counts.map(count => count || 0);
    });

    return { months, policyCounts };
};

export default preprocess_policy;
