import instance from "../api";

const addBeneficiary = async (fname, lname, relation, gender, DOB, city, address, email, phoneNumber, image) => {
    const request = {
        "fname": fname,
        "lname": lname,
        "gender": gender,
        "DOB": DOB,
        "email": email,
        "contact": phoneNumber,
        "city": city,
        "address": address,
        "relation": relation,
        "image": image
    }

    try {
        const response = await instance.post("add_beneficiary", request);
        return response.data;
    }
    catch (err) {
        return {
            success: "false",
            message: `Connection error: ${err.message}`
        }
    }
};

export default addBeneficiary;