import { Button, Img, Text } from "components";
import React from "react";

const CompareStats = ({ difference, timeframe, include_timeframe=false }) => {

    return (
        <div>
            {difference > 0 &&
                <Text
                    className="text-blue_gray-400 text-xs tracking-[0.50px] w-auto flex items-end"
                    size="txtMontserratMedium14"
                >
                    <Button
                        className="cursor-pointer flex items-center justify-center min-w-[59px] px-1 mr-1"
                        leftIcon={
                            <Img
                                className="h-4 mr-1"
                                src="images/img_arrowleft.svg"
                                alt="arrow_left"
                            />
                        }
                        shape="round"
                        color="green_50"
                        variant="fill"
                    >
                        <div className="!text-green-800 font-semibold text-right text-xs tracking-[0.50px]">
                            {difference}%
                        </div>
                    </Button> 
                    {include_timeframe && `last ${timeframe}`}
                </Text>
            }
            {difference === 0 &&
                <Text
                    className="text-blue_gray-400 text-xs tracking-[0.50px] w-auto flex items-end"
                    size="txtMontserratMedium14"
                >
                    <Button
                        className="cursor-pointer flex items-center justify-center min-w-[59px] px-1 mr-1"
                        shape="round"
                        color="blue_gray_50_01"
                        variant="fill"
                    >
                        <div className="!text-gray-700 font-semibold text-right text-xs tracking-[0.50px]">
                            - {difference}%
                        </div>
                    </Button> 
                    {include_timeframe && `last ${timeframe}`}
                </Text>
            }
            {difference < 0 &&
                <Text
                    className="text-blue_gray-400 text-xs tracking-[0.50px] w-auto flex items-end"
                    size="txtMontserratMedium14"
                >
                    <Button
                        className="cursor-pointer flex items-center justify-center min-w-[59px] px-1 mr-1"
                        leftIcon={
                            <Img
                                className="h-4 mr-1"
                                src="images/img_arrowright.svg"
                                alt="arrow_left"
                            />
                        }
                        shape="round"
                        color="deep_orange_50"
                        variant="fill"
                    >
                        <div className="!text-orange-800 font-semibold text-right text-xs tracking-[0.50px]">
                            {difference}%
                        </div>
                    </Button> 
                        {include_timeframe && `last ${timeframe}`}
                    </Text>
            }
                </div>
    )
};

export { CompareStats };