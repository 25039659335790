import React from 'react';

const LoadingModal = () => {
  return (
    <div className="loading-overlay z-20">
      <div className="loading-content">
        <img src="images/loading.gif" alt="Loading..." />
      </div>
    </div>
  );
};

export { LoadingModal };
