import React from 'react';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from 'react-chartjs-2';
import preprocess_policy from 'utils/preprocess_policy_data';

Chart.register(CategoryScale);

const PolicyLineGraph = ({ data, timeframe }) => {
    data = preprocess_policy(data, timeframe);

    const { months, policyCounts } = data;

    const chartData = {
        labels: months,
        datasets: [
            {
                label: 'Under 60',
                data: policyCounts[0]
            },
            {
                label: 'Above 60',
                data: policyCounts[1]
            },
        ],
    };

    const chartOptions = {
        scales: {
          y: {
            min: 0
        },
        },
      };

    return (
        <div>
            <Line data={chartData}
            options={chartOptions}
            />
        </div>
    );
};

export { PolicyLineGraph };
