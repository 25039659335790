import React from 'react';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from 'react-chartjs-2';
import preprocess_customer_data from 'utils/preprocess_customer_data';

Chart.register(CategoryScale);

const CustomerLineGraph = ({ data, timeframe }) => {
    data = preprocess_customer_data(data, timeframe);

    const { times, customer_count } = data;

    const chartData = {
        labels: times,
        datasets: [{
            label: "Registered customers",
            data: customer_count
        }],
    };

    const chartOptions = {
        scales: {
          y: {
            min: 0
        },
        },
      };

    return (
        <div>
            <Line data={chartData}
            options={chartOptions}
            />
        </div>
    );
};

export { CustomerLineGraph };
